// If source is the same as less than x minutes ago then don't add to array
const minutesPastPreviousSamePmVisit = 60
const trackWhenAnyPropertyHasContent = ['utm_campaign', 'utm_medium', 'utm_source', 'utm_term']

export const utmListLocalStorageKey = 'trackingUtmList'
export const gaIdCookieKey = '_ga'

export default defineNuxtPlugin(({ query }) => {
  try {
    if (!window.localStorage) {
      return
    }
  } catch {
    return
  }

  const parsePmVisitSource = () => {
    const utmSource = !query?.utm_source ? '' : decodeURIComponent(query.utm_source.replace(/\+/g, ' '))
    const utmCampaign = !query?.utm_campaign ? '' : decodeURIComponent(query.utm_campaign.replace(/\+/g, ' '))
    const utmMedium = !query?.utm_medium ? '' : decodeURIComponent(query.utm_medium.replace(/\+/g, ' '))
    const utmTerm = !query?.utm_term ? '' : decodeURIComponent(query.utm_term.replace(/\+/g, ' '))

    let httpReferrer = document.referrer
    const httpReferrerLength = httpReferrer.length
    httpReferrer = httpReferrer.substr(0, 2000)
    let landingPage = null
    let landingPageLength = 0
    try {
      landingPage = window.location.href.toString().split(window.location.host)[1]
      landingPageLength = landingPage.length
      landingPage = landingPage.substr(0, 2000)
    } catch {
      // Ignore the error and continue execution.
    }

    return {
      utm_source: utmSource.substr(0, 100),
      utm_campaign: utmCampaign.substr(0, 100),
      utm_medium: utmMedium.substr(0, 100),
      utm_term: utmTerm.substr(0, 100),
      http_referrer: httpReferrer,
      http_referrer_length: httpReferrerLength,
      landingpage: landingPage,
      landingpage_length: landingPageLength,
      time: (new Date()) / 1E3 | 0,
    }
  }

  const newPmVisitSource = parsePmVisitSource()

  if (trackWhenAnyPropertyHasContent.every(property => newPmVisitSource[property]?.length === 0)) {
    return
  }

  const pmVisitSourcesStorage = window.localStorage.getItem(utmListLocalStorageKey)

  if (pmVisitSourcesStorage) {
    let prevPmVisitSources = []
    try {
      prevPmVisitSources = JSON.parse(pmVisitSourcesStorage)
    } catch {
      // Ignore the error and continue execution.
    }

    if (prevPmVisitSources?.length) {
      const lastPmVisitSource = prevPmVisitSources[prevPmVisitSources.length - 1]
      const sameTimeMsThrottle = 60 * minutesPastPreviousSamePmVisit

      if (
        decodeURIComponent(lastPmVisitSource.landingpage) !== decodeURIComponent(newPmVisitSource.landingpage) ||
        lastPmVisitSource.time + sameTimeMsThrottle < newPmVisitSource.time
      ) {
        prevPmVisitSources.push(newPmVisitSource)
        window.localStorage.setItem(utmListLocalStorageKey, JSON.stringify(prevPmVisitSources))
      }
    } else {
      window.localStorage.setItem(utmListLocalStorageKey, JSON.stringify([newPmVisitSource]))
    }
  } else {
    window.localStorage.setItem(utmListLocalStorageKey, JSON.stringify([newPmVisitSource]))
  }
})
