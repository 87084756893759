// Translated
// Migrated
<template lang="pug">
VueMultiselect.phone-country-select(
  :id="id",
  ref="ms",
  v-model="countryCode",
  label="value",
  :track-by="trackBy",
  deselect-label=" ",
  group-label="type",
  group-values="list",
  :group-select="false",
  :allow-empty="false",
  :show-labels="false",
  :options="countries",
  :disabled="disabled",
  :placeholder="$t('formSelectCountryPlaceholder')",
  :searchable="true",
  :max-height="maxHeightPx"
  @update:model-value="changeCode",
  @select="$emit('select', $event)"
)
  template(#singleLabel="props")
    .d-flex.align-items-center(
      data-i18n="formSelectCountryPlaceholder"
    )
      img.phone-country-select__country-flag.mr-2(
        :src="`/countries/${props.option.key.toLowerCase()}.svg`"
      )
      | +{{ props.option.dial_code }}
  template(#option="props", )
    .d-flex(v-if="props.option.$isLabel")
    .d-flex(v-else)
      img.phone-country-select__country-flag(
        loading="lazy",
        :src="`/countries/${props.option.key.toLowerCase()}.svg`"
      )
      .ml-2 +{{ props.option.dial_code }}
      .ml-2(v-if="showCountryText") {{ props.option.value }}
</template>

<script>
import { mapState } from 'pinia'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.esm.css'

export default defineNuxtComponent({
  components: {
    VueMultiselect,
  },

  props: {
    value: {
      type: [Number, String],
      default: null,
    },

    dialCode: {
      type: Boolean,
      default: false,
    },

    trackByValue: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    id: {
      type: String,
      default: null,
    },

    showCountryText: {
      type: Boolean,
      default: true,
    },

    maxHeightPx: {
      type: Number,
      default: 300,
    },
  },

  emits: ['input', 'select'],

  data () {
    return {
      countryCode: {},
    }
  },

  created () {
    this.countryCode = this.getDefaultCountry
  },

  computed: {
    ...mapState(useLocaleStore, {
      commonCountries: 'commonCountries',
      countriesState: 'countries',
      getDefaultCountry: 'getDefaultCountry',
    }),

    trackBy () {
      if (this.dialCode) {
        return 'dial_code'
      }

      return this.trackByValue
        ? 'value'
        : 'key'
    },

    countries () {
      return [
        {
          type: 'common',
          list: this.commonCountries,
        },
        {
          type: 'all',
          list: this.countriesState.filter((c) => {
            return c.dial_code && !this.commonCountries.find(cc => c.key === cc.key)
          }),
        },
      ]
    },
  },

  watch: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    value (v) {
      this.countryCode = this.countriesState.find(c => c[this.trackBy] === this.value)
    },
  },

  mounted () {
    if (!this.value) {
      return this.changeCode(this.countryCode)
    }

    this.countryCode = this.countriesState.find(c => c[this.trackBy] === this.value)
  },

  beforeUnmount () {
    this.$refs.ms?.deactivate?.()
  },

  methods: {
    changeCode (value) {
      this.countryCode = value

      this.$emit('input', value)
    },
  },
})
</script>

<style lang="scss">
$flag-max-height: 16px;

.phone-country-select {
  .multiselect {
    border: none;

    &__select,
    &__tags {
      height: 100% !important;
    }

    &__select {
      padding-bottom: 10px;
    }

    &__content-wrapper {
      overflow-x: hidden;
    }

    &__tags {
      display: flex;
      align-items: center;
      background: none;
      border: none;
    }

    &__option {
      &--disabled {
        min-height: unset;
        padding: 3px;
      }
    }
  }

  &__country-flag {
    height: $flag-max-height;
    max-height: $flag-max-height;
  }
}
</style>