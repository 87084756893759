<template lang="pug">
NuxtLoadingIndicator(:color="loadingColor")
.position-relative(:class="wrapperClass")
  App
    slot
</template>

<script setup>
import generateFlags from 'nuxt-cache-ssr/dist/runtime/generateFlags'

useConfigStore()
const route = useRoute()
const { t } = useI18n()

const headers = useRequestHeaders()

const userAgent = headers['user-agent'] || ''
const whitelabelStore = useWhitelabelStore()

const { $isSol } = useNuxtApp()

const rootStore = useRootStore()

const {
  modalActive,
  tripTypesSidebar,
} = storeToRefs(rootStore)

const { locale } = useLocaleStore()

const whitelableColor = computed(() => {
  return whitelabelStore.getWhitelabel ? whitelabelStore.getWhitelabel.primaryColor : '#fecb21'
})
const whitelableTextColor = computed(() => {
  return whitelabelStore.getWhitelabel ? 'white' : 'black'
})

const localeLangInfo = localeToLangInfo(locale)

const wrapperClass = computed(() => {
  const classes = []

  if (!modalActive.value) {
    classes.push('overflow-clip')
  }

  return classes.join(' ')
})

const loadingColor = computed(() => isSol() ? '#fecb21' : '#065BA2')

onServerPrefetch(async () => {
  if ($isSol && route.query?.wl) {
    whitelabelStore.SET_WHITE_LABEL(route.query?.wl)
  }

  await rootStore.handleAgentCookie(route.query?.a)

  const fetchPromises = [
    rootStore.fetchAgentCall(),
    rootStore.fetchTopNavbar(),
    rootStore.fetchFooter(),
    rootStore.fetchAlert(),
  ]

  const flags = generateFlags(headers, userAgent)
  if (!$isSol && (!userAgent || !flags.isMobile)) {
    fetchPromises.push(rootStore.fetchTripTypesSidebar())
  }

  try {
    await Promise.all(fetchPromises)
  } catch (error) {
    throw createError({
      statusCode: 500,
      statusMessage: error.message,
    })
  }
})

onMounted(async () => {
  if (!$isSol && !Object.keys(tripTypesSidebar.value).length) {
    await rootStore.fetchTripTypesSidebar(false)
  }
  whitelabelStore.checkAgentCookie()
})

useHead({
  htmlAttrs: {
    lang: () => localeLangInfo.code,
  },
})

useSchemaOrg([
  defineWebSite({
    description: t('homeDescription'),
    inLanguage: localeLangInfo.iso,
  }),
])
</script>

<style lang="scss">
.btn-orange {
  background: v-bind(whitelableColor) !important;
  color: v-bind(whitelableTextColor) !important;
}
</style>
