<template lang="pug">
.newsletter-popup.px-3.py-3.px-md-0.py-md-0.drop-shadow-dark.cursor-pointer(
  v-if="showModal"
  v-click-outside="{ fn: compressPopup, ignore: '.newsletter-popup-base' }"
  :class="expanded ? 'expanded' : '' ",
  @click="expanded = true"
)
  .serif-font.d-flex.h-100.align-items-center.justify-content-center(v-if="!expanded")
    fa.mr-3(
      icon="envelope"
      size="xl"
      color="white"
    )
    .d-flex.text-white.banner-text
      p.m-0(data-i18n="newsletterPopupBannerTitle") {{ $t('newsletterPopupBannerTitle') + ' –' }}
      p.font-weight-bold.m-0.ml-1(data-i18n="newsletterPopupBannerInsentive") {{ $t('newsletterPopupBannerInsentive') }}
    fa.ml-3(
      icon="chevron-down"
      size="lg"
      color="white"
    )

  .newsletter-popup-base.d-flex.h-100.pl-md-3.cursor-default(v-if="expanded")
    Loading.h-100.w-100.d-flex.justify-content-center.align-items-center(v-if="loading")
    .newsletter-text.text-medium-blue.py-4.pr-2(v-if="!success && !loading")
      .h5.serif-font(data-i18n="newsletterPopupTitle") {{ $t('newsletterPopupTitle') }}
      p.mb-2.ingress(
        data-i18n="newsletterPopupIngress"
        v-html="$t('newsletterPopupIngress')"
      )
      .alert.alert-danger(v-if="error" ) {{ $t('bookErrorTitle') }}
      ul(v-if="!error")
        li.mb-2(v-for="point in bulletPoints" :key="point" :data-i18n="point")
          fa(
            icon="check"
            size="lg"
          )
          span.m-0.ml-2 {{ $t(point) }}
      form
        input.mb-2.px-3.text-medium-blue(
          v-model="formData.email"
          type="email"
          data-i18n="newsletterPopupInputPH"
          :placeholder="$t('newsletterPopupInputPH')"
          :class="{ 'is-invalid': v$.email.$error }"
        )
        button.btn.btn-blue(type="submit" data-i18n="newsletterPopupButton" @click="add") {{ $t('newsletterPopupButton') }}

    .newsletter-text.success.d-flex.flex-column.text-medium-blue.py-4.pr-2.cursor-default(v-if="success && isNewSubscriber && !loading")
      .d-flex.flex-column
        .h5.serif-font(data-i18n="newsletterPopupSuccessTitle") {{ $t('newsletterPopupSuccessTitle') }}
        p.mb-2.ingress(
          data-i18n="newsletterPopupSuccessText"
          v-html="$t('newsletterPopupSuccessText')"
        )
        i(data-i18n="newsletterPopupSuccessWait") {{ $t('newsletterPopupSuccessWait') }}
      button.btn.btn-blue(type="submit" @click="close") {{ $t('close') }}

    .newsletter-text.success.d-flex.flex-column.text-medium-blue.py-4.pr-2.cursor-default(v-if="success && !isNewSubscriber && !loading")
      .d-flex.flex-column
        .h5.serif-font(data-i18n="newsletterPopupOldEmailTitle") {{ $t('newsletterPopupOldEmailTitle') }}
        p.mb-2.ingress(
          data-i18n="newsletterPopupOldEmailText"
          v-html="$t('newsletterPopupOldEmailText')"
        )
      button.btn.btn-blue(type="submit" @click="close") {{ $t('close') }}
    .newsletter-image
      ResponsiveImage.h-100.w-100(
        :image="image"
        alt="Newsletter popup image"
      )
  ScreenWidthProvider(v-slot="{ isNarrower }")
    ModalCloseButton(
      :align="isNarrower(375) ? 'inside' : 'border'"
      :button-left="isNarrower('md') ? false : true"
      shadow
      color="medium-blue"
      background-color="white"
      @click="showModal = false"
    )
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import localStorageKeys from '@layers/web/constants/local-storage-keys'

const localeStore = useLocaleStore()
const loading = ref(false)
const success = ref(false)
const expanded = ref(false)
const showModal = ref(false)
const error = ref(false)
const newsletterPopup = ref(null)
const isNewSubscriber = ref(true)

const bulletPoints = [
  'newsletterPopupPointOne',
  'newsletterPopupPointTwo',
  'newsletterPopupPointThree',
]
const image = 'campaign/shutterstock_1036002985_p9wytw.jpg'

onMounted(() => {
  try {
    newsletterPopup.value = window.localStorage.getItem(localStorageKeys.hideNewsletter)
  } catch {
    //
  }

  if (newsletterPopup.value) {
    showModal.value = false
  } else {
    showModal.value = true
  }
})

const compressPopup = () => {
  expanded.value = false
}

const formData = reactive({
  email: '',
})

const rules = computed(() => ({
  email: {
    email,
    required,
  },
}))

const v$ = useVuelidate(rules, formData)

const add = async () => {
  v$.value.$touch()

  if (v$.value.$invalid) {
    return
  }

  loading.value = true

  try {
    const request = {
      email: formData.email,
    }

    await apiFetch(`/${localeStore.locale}/newsletter-register?campaign=discount`, {
      method: 'POST',
      body: request,
    }).then((res) => {
      if (!res.data.new){
        isNewSubscriber.value = false
        loading.value = false
        success.value = true
        window.localStorage.setItem(localStorageKeys.hideNewsletter, true)
      } else {
        isNewSubscriber.value = true
        loading.value = false
        success.value = true
        window.localStorage.setItem(localStorageKeys.hideNewsletter, true)
      }
    }).catch(() => {
      loading.value = false
      error.value = true
    })
  } catch (e) {
    loading.value = false
    error.value = true
  }
}
const close = () => {
  showModal.value = false
}
</script>

<style lang="scss" scoped>
.newsletter-popup {
  z-index: 101;
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 436px;
  height: 50px;
  background-color: $medium-blue;
  transition: all 0.3s ease, transform 0.3s ease;

  &.expanded {
    bottom: 20px;
    right: 20px;
    height: 340px;
    width: 530px;
    background-color: $white-blue;

  }
}
  .newsletter-text {
    width: 55%;
    font-size: 0.8rem;
  }
  .newsletter-text.success {
    justify-content: space-between;
  }
.newsletter-image {
  width: 50%;
  min-width: 240px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

form {
  width: 100%;
  max-width: 240px;

  input, button {
    height: 47px;
    width: 100%;
    border: 1px solid $medium-blue;

    &::placeholder {
      font-weight: bold;
      text-align: center;
      font-size: 0.9rem;
      opacity: 1;
      color: $medium-blue;
    }
  }
  button {
    font-size: 0.9rem;
    text-transform: none;
  }
}

@media (max-width: 767px) {
  .newsletter-popup {
    width: 100%;
    max-width: 340px;
    height: 50px;
    bottom: 10px;
    right: 0;
    left: 0;
    margin: auto;

    &.expanded {
      width: 100%;
      max-width: 340px;
      height: 580px;
      right: 0;
      left: 0;
      margin: auto;

    }
  }
  .banner-text {
    font-size: 0.7rem;
  }
  .newsletter-popup-base {
    flex-direction: column-reverse;
  }
  .newsletter-image {
    height: 35%;
    min-width: unset;
    width: 100%;
  }

  .newsletter-text {
    height: 65%;
    width: 100%;

    .h5 {
      font-size: 1.5rem;
    }
    p {
      font-size: 0.85rem;
    }
  }
  form {
    width: 100%;
    max-width: unset;

    input, button {
      height: 47px;
      width: 100%;

      &::placeholder {
        font-size: 0.9rem;

      }
    }
    button {
      font-size: 0.9rem;
    }
  }
}
</style>