import {
  library,
  config,
} from '@fortawesome/fontawesome-svg-core'
import {
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faArrowRightLong,
  faArrowRight,
  faArrowLeft,
  faStar,
  faCaretDown,
  faCaretSquareDown,
  faCaretSquareUp,
  faPlane,
  faEnvelope,
  faBedFront,
  faSuitcaseRolling,
  faBus,
  faSearch,
  faPlus,
  faPlusSquare,
  faPlusCircle,
  faMinus,
  faMinusCircle,
  faTimes,
  faPhone,
  faTag,
  faUser,
  faBan,
  faInfoCircle,
  faExclamationCircle,
  faBars,
  faMapMarkerAlt,
  faMapLocationDot,
  faMap,
  faSun,
  faUmbrellaBeach,
  faLocationDot,
  faCircleXmark,
  faCircle,
  faVideo,
  faClock,
  faCalendar,
  faHotel,
  faRotateLeft,
  faCheck,
  faQuoteLeft,
  faQuoteRight,
  faSunBright,
  faTaxiBus,
  faLink,
  faCopy,
  faImage,
  faUtensils,
  faFamily,
  faTableCellsLarge,
  faTags,
} from '@fortawesome/pro-solid-svg-icons'

export default defineNuxtPlugin(() => {
  config.autoAddCss = false

  library.add({
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faArrowRightLong,
    faArrowRight,
    faArrowLeft,
    faStar,
    faCaretDown,
    faCaretSquareDown,
    faCaretSquareUp,
    faPlane,
    faEnvelope,
    faBedFront,
    faSuitcaseRolling,
    faBus,
    faSearch,
    faPlus,
    faPlusSquare,
    faPlusCircle,
    faMinus,
    faMinusCircle,
    faTimes,
    faPhone,
    faTag,
    faUser,
    faBan,
    faInfoCircle,
    faLink,
    faExclamationCircle,
    faBars,
    faCopy,
    faMapMarkerAlt,
    faMapLocationDot,
    faMap,
    faSun,
    faUmbrellaBeach,
    faLocationDot,
    faCircleXmark,
    faCircle,
    faVideo,
    faClock,
    faCalendar,
    faHotel,
    faRotateLeft,
    faCheck,
    faSunBright,
    faQuoteLeft,
    faQuoteRight,
    faTaxiBus,
    faImage,
    faUtensils,
    faFamily,
    faTableCellsLarge,
    faTags,
  })
})
