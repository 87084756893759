import { default as _91slug_93dB7PgpEaLDMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue?macro=true";
import { default as _91slug_93CoY5PEjcn5Meta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue?macro=true";
import { default as _91name_93q0mAQYJb0oMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue?macro=true";
import { default as _91paxid_93MwSjcE2lA0Meta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/satisfactionstatus/[tripid]/[paxid].vue?macro=true";
import { default as _91id_93oLOtkn5FGuMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/payment/[id].vue?macro=true";
import { default as _91id_93brZmeTRj5sMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/omdome/[id].vue?macro=true";
import { default as _91tab_938Jga8ph92GMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue?macro=true";
import { default as _91tab_939mW3cR127KMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/lectures/[tab].vue?macro=true";
import { default as _91tab_93gpU4p8URIYMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/connections/[tab].vue?macro=true";
import { default as _91tab_93Cmo455fo4JMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/about/[tab].vue?macro=true";
import { default as _91slug_93UvcVlJ6QWPMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/info/[slug].vue?macro=true";
import { default as _91id_93nrnpHPsCbDMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/fn/[id].vue?macro=true";
import { default as _91tab_937LZw6xTrqbMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/destination/[slug]/[tab].vue?macro=true";
import { default as _91slug_936CcfyksEzsMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/competition/[slug].vue?macro=true";
import { default as _91slug_93gsoslDv5wvMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/category/[slug].vue?macro=true";
import { default as _91ocr_93vZcVW26p7jMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue?macro=true";
import { default as _91ocr_93KZWmsAgAGuMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue?macro=true";
import { default as _91id_93lQbu9g0oNsMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue?macro=true";
import { default as _91step_939XjhX43OBPMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue?macro=true";
import { default as _91slug_936GsJOnGAtYMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/[slug].vue?macro=true";
import { default as _91jwt_93hefXZlSeWEMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue?macro=true";
import { default as _91tab_93DzSZ5VJAz6Meta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue?macro=true";
import { default as indexJH54xfW6p8Meta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/app/index.vue?macro=true";
import { default as callback9zze2z7vOhMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/callback.vue?macro=true";
import { default as blackweekk1a9HBuHjvMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/blackweek.vue?macro=true";
import { default as _91slug_93cpbidmLfWCMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/[slug].vue?macro=true";
import { default as indexrtcDwHDewtMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/index.vue?macro=true";
import { default as byebyeieTYwIH1EO2RMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/byebyeie.vue?macro=true";
import { default as indexsVzggAH5aOMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/index.vue?macro=true";
import { default as lastminuteBGjI13yp4PMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/lastminute.vue?macro=true";
import { default as _91name_93CKMSntk3xGMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/[name].vue?macro=true";
import { default as index9ldNoJlsseMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/index.vue?macro=true";
import { default as cataloguedo6Fo0DALZMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/catalogue.vue?macro=true";
import { default as complaintxyFs39zxFGMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/complaint.vue?macro=true";
import { default as dinbokning_45aktuellZMXpyrpZ8TMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue?macro=true";
import { default as _91slug_93hkJx58T363Meta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/[slug].vue?macro=true";
import { default as indexmjLcPBMyN9Meta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/index.vue?macro=true";
import { default as faq5u3DF38h0JMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/faq.vue?macro=true";
import { default as _91id_93eGq7JmfhzDMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue?macro=true";
import { default as indexXpQY2PyDHuMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/giftcard/index.vue?macro=true";
import { default as _91name_93XuktqhokZ7Meta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/hotels/[name].vue?macro=true";
import { default as indexay9MH7LcqyMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/hotels/index.vue?macro=true";
import { default as indexUPpdlpfl3WMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/index.vue?macro=true";
import { default as grouptravelJE4lbLvDU6Meta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/grouptravel.vue?macro=true";
import { default as index7dsaovxxX9Meta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/index.vue?macro=true";
import { default as lastminuteervMLrERLRMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/lastminute.vue?macro=true";
import { default as map6zoDQswtCvMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/map.vue?macro=true";
import { default as dinbokningGNUPvOPaSuMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue?macro=true";
import { default as newslettertujI7fcY87Meta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/newsletter.vue?macro=true";
import { default as offlineWlmctGs5fLMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/offline.vue?macro=true";
import { default as failed9FyKkY1jonMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/payment/failed.vue?macro=true";
import { default as indexRNSCUKgzLvMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/review/index.vue?macro=true";
import { default as searchFdFKvULNzXMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/search.vue?macro=true";
import { default as indexxVkDgXX7WEMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/tourleader/index.vue?macro=true";
import { default as verification_45doneC8S2gyqhZJMeta } from "/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/verification-done.vue?macro=true";
export default [
  {
    name: "tourleader-id",
    path: "/reseledare/:id()",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "titinerary",
    path: "/dagprogram",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "shipinfo",
    path: "/fartyg",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "satisfactionstatus-tripid",
    path: "/nojdhet-status/:tripid()",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/satisfactionstatus/[tripid]/[paxid].vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "omdome",
    path: "/omdome",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "mytrip",
    path: "/dinresa",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "info-lectures",
    path: "/info/forelasningar",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/lectures/[tab].vue")
  },
  {
    name: "info-connections",
    path: "/info/anslutning",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/connections/[tab].vue")
  },
  {
    name: "info-about",
    path: "/info/om",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/about/[tab].vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "fn",
    path: "/fn",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "destination-slug",
    path: "/destination/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/destination/[slug]/[tab].vue")
  },
  {
    name: "competition",
    path: "/tavling",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "category",
    path: "/kategori",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/category/[slug].vue")
  },
  {
    name: "bookingfaq",
    path: "/boknings-faq",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "booking-finish",
    path: "/booking-finish",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "booking-confirmation",
    path: "/booking-confirmation",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "book-tripid",
    path: "/boka/:tripid()",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "auth",
    path: "/medlem",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/[slug].vue")
  },
  {
    name: "app-l",
    path: "/app/l",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue")
  },
  {
    name: "triptype-slug",
    path: "/:triptype()/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "triptype-slug-tab",
    path: "/:triptype()/:slug()/:tab()",
    meta: _91tab_93DzSZ5VJAz6Meta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/app/index.vue")
  },
  {
    name: "app-l-jwt",
    path: "/app/l/:jwt()",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue")
  },
  {
    name: "auth-slug",
    path: "/medlem/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/[slug].vue")
  },
  {
    name: "auth-callback",
    path: "/medlem/callback",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/callback.vue")
  },
  {
    name: "blackweek",
    path: "/blackweek",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/blackweek.vue")
  },
  {
    name: "blog-slug",
    path: "/blogg/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blogg",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/index.vue")
  },
  {
    name: "book-tripid-step",
    path: "/boka/:tripid()/:step()",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "booking-confirmation-id",
    path: "/booking-confirmation/:id()",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "booking-finish-ocr",
    path: "/booking-finish/:ocr()",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "bookingfaq-ocr",
    path: "/boknings-faq/:ocr()",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "byebyeie",
    path: "/byebyeie",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/byebyeie.vue")
  },
  {
    name: "calendar",
    path: "/kalender",
    meta: indexsVzggAH5aOMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/index.vue")
  },
  {
    name: "calendar-lastminute",
    path: "/kalender/sista-minuten",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/lastminute.vue")
  },
  {
    name: "campaign-name",
    path: "/kampanj/:name()",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/[name].vue")
  },
  {
    name: "campaign",
    path: "/kampanj",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/index.vue")
  },
  {
    name: "catalogue",
    path: "/katalog",
    meta: cataloguedo6Fo0DALZMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/catalogue.vue")
  },
  {
    name: "category-slug",
    path: "/kategori/:slug()",
    meta: _91slug_93gsoslDv5wvMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/category/[slug].vue")
  },
  {
    name: "competition-slug",
    path: "/tavling/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "complaint",
    path: "/reklamation",
    meta: complaintxyFs39zxFGMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/complaint.vue")
  },
  {
    name: "destination-slug-tab",
    path: "/destination/:slug()/:tab()",
    meta: _91tab_937LZw6xTrqbMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/destination/[slug]/[tab].vue")
  },
  {
    name: "dinbokning-aktuell",
    path: "/dinbokning-aktuell",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue")
  },
  {
    name: "event-slug",
    path: "/event/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/[slug].vue")
  },
  {
    name: "event",
    path: "/event",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/faq.vue")
  },
  {
    name: "fn-id",
    path: "/fn/:id()",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "giftcard-id",
    path: "/presentkort/:id()",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue")
  },
  {
    name: "giftcard",
    path: "/presentkort",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/giftcard/index.vue")
  },
  {
    name: "hotels-name",
    path: "/hotell/:name()",
    meta: _91name_93XuktqhokZ7Meta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/hotels/[name].vue")
  },
  {
    name: "hotels",
    path: "/hotell",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/hotels/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/index.vue")
  },
  {
    name: "info-slug",
    path: "/info/:slug()",
    meta: _91slug_93UvcVlJ6QWPMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "info-about-tab",
    path: "/info/om/:tab()",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/about/[tab].vue")
  },
  {
    name: "info-connections-tab",
    path: "/info/anslutning/:tab()",
    meta: _91tab_93gpU4p8URIYMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/connections/[tab].vue")
  },
  {
    name: "info-grouptravel",
    path: "/info/gruppresor",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/grouptravel.vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/index.vue")
  },
  {
    name: "info-lectures-tab",
    path: "/info/forelasningar/:tab()",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/lectures/[tab].vue")
  },
  {
    name: "lastminute",
    path: "/sista-minuten-erbjudanden",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/lastminute.vue")
  },
  {
    name: "map",
    path: "/karta",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/map.vue")
  },
  {
    name: "mytrip-tab",
    path: "/dinresa/:tab()",
    meta: _91tab_938Jga8ph92GMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "mytrip-dinbokning",
    path: "/mytrip/dinbokning",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue")
  },
  {
    name: "newsletter",
    path: "/nyhetsbrev",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/newsletter.vue")
  },
  {
    name: "offline",
    path: "/offline",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/offline.vue")
  },
  {
    name: "omdome-id",
    path: "/omdome/:id()",
    meta: _91id_93brZmeTRj5sMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "payment-id",
    path: "/payment/:id()",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "payment-failed",
    path: "/payment/failed",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/payment/failed.vue")
  },
  {
    name: "review",
    path: "/utvardera-resa",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/review/index.vue")
  },
  {
    name: "satisfactionstatus-tripid-paxid",
    path: "/nojdhet-status/:tripid()/:paxid()",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/applications/rolfsbuss-web/pages/satisfactionstatus/[tripid]/[paxid].vue")
  },
  {
    name: "search",
    path: "/sok",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/search.vue")
  },
  {
    name: "shipinfo-name",
    path: "/fartyg/:name()",
    meta: _91name_93q0mAQYJb0oMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "titinerary-slug",
    path: "/dagprogram/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "tourleader-id-slug",
    path: "/reseledare/:id()/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "tourleader",
    path: "/reseledare",
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/tourleader/index.vue")
  },
  {
    name: "verification-done",
    path: "/verification-done",
    meta: verification_45doneC8S2gyqhZJMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-01-27-0826/rtg-monorepo/src/layers/web/pages/verification-done.vue")
  }
]