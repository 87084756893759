// Translated
// Migrated
<template lang="pug">
component.d-block(:is="parent.component" v-bind="parent.props")
  .tripresult(:class="[ { 'keep-headline-in-mobile': headerOutsideImageInMobile }, ...parentClass ]")
    .tripresult__content.d-sm-block(
      :class="{ 'd-none': mobileContentIsEmpty, 'w-100': !hasImageSection }"
    )
      slot(name="top")
      .tripresult__content__headline(:class="{ 'keep-headline-in-mobile': headerOutsideImageInMobile }")
        component.d-flex.align-items-center.flex-wrap.pt-2(:is="xs ? 'h5' : 'h2'" v-if="title")
          span.text-medium-blue(v-if="isNew") {{ $t('newTrip') }}&nbsp;
          span.mr-3 {{ title }}
        component(:is="xs ? 'h6' : 'h3'" v-if="subtitle") {{ subtitle }}

      .tripresult__content__description(v-if="slotsOpen.description")
        slot(name="description")

      .d-flex.justify-content-between.align-items-end(v-if="slotsOpen.bottomLeft || slotsOpen.bottomRight")
        slot(name="bottomLeft")
          div
        slot(name="bottomRight")
          div

    .tripresult__thumb(v-if="hasImageSection")
      ResponsiveHotelImage.tripresult__thumb__img(v-if="image" lazy, :alt="title" :image="image" cover, :type="imageType" :in-view-scroll-target="imageInViewScrollTarget")
        .tripresult__thumb__cost.font-weight-bold(v-if="slotsOpen.labels && price" :class="[`tripresult__thumb__cost--${labelColor}`]")
          slot(name="labels")

      .tripresult__thumb__mobile-title(v-if="!headerOutsideImageInMobile")
        div
          h2(v-if="title").d-flex.align-items-center.flex-wrap
            span.mr-3 {{ title }}
          h3(v-if="subtitle") {{ subtitle }}
</template>

<script>
import { NuxtLink } from '#components'

export default defineNuxtComponent({
  components: {
    NuxtLink,
  },

  props: {
    price: {
      type: Number,
      default: null,
    },
    link: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      default: '',
    },

    subtitle: {
      type: String,
      default: '',
    },

    image: {
      type: String,
      default: '',
    },

    imageType: {
      type: String,
      default: 'cloudinary',
    },

    fallbackImage: {
      type: String,
      default: 'hero/rome_sqg4ot',
    },

    headerOutsideImageInMobile: {
      type: Boolean,
      default: false,
    },

    parentClass: {
      type: Array,
      default: () => [],
    },

    xs: {
      type: Boolean,
      default: false,
    },

    isNew: {
      type: Boolean,
      default: false,
    },

    imageInViewScrollTarget: {
      type: htmlTypes.HTMLElement,
      default: undefined,
    },
  },

  computed: {
    slotsOpen () {
      return {
        labels: slotHasContent('labels', this.$slots),
        description: slotHasContent('description', this.$slots),
        bottomLeft: slotHasContent('bottomLeft', this.$slots),
        bottomRight: slotHasContent('bottomRight', this.$slots),
      }
    },

    parent () {
      if (this.link) {
        return {
          component: 'NuxtLink',
          props: { to: this.link, class: `__size--${this.xs ? 'xs' : 'lg'}` },
        }
      }
      return {
        component: 'div',
        props: { class: `__size--${this.xs ? 'xs' : 'lg'}` },
      }
    },

    mobileContentIsEmpty () {
      // TODO: Extend with slots
      const content = this.slotsOpen.description || this.slotsOpen.bottomLeft || this.slotsOpen.bottomRight
      return !this.headerOutsideImageInMobile && !content
    },

    labelColor () {
      return this.$isSol ? 'yellow' : 'blue'
    },

    hasImageSection () {
      return (
        this.slotsOpen.labels ||
        this.image ||
        !this.headerOutsideImageInMobile
      )
    },
  },
})
</script>

<style lang="scss">
@import "@layers/web/assets/scss/modules/product/_product-trip-result";

/*! purgecss start ignore */
.tripresult__thumb__cost{
  &--blue {
    background: $blue !important;
    color: $white !important;
  }
  &--yellow {
    background: $orange !important;
    color: $black !important;
  }
}
.__size {
  &--xs {
    .tripresult {
      &__content {
        &__headline {
          margin-bottom: .5rem;
        }

        &__description {
          font-size: .75rem;
        }
      }

      &__thumb__cost {
        font-size: .75rem;
        height: 2.25rem;
        width: auto;
        padding: 0 1rem;
      }
    }
  }
}
/*! purgecss end ignore */
</style>
