import dayjs from 'dayjs'
import { DATEPICKER_DATE_FORMAT } from '@packages/date/formats'

export const useCharterStore = defineStore('charter', {
  state: () => ({
    destination: {},

    startpage: {},
    tagsFeatured: [],
    timeout: 60 * 1000,
    selectedAirport: null,
    selectedDestination: null,
    selectedDestinationL1: null,
    rooms: [{
      Adults: 2,
      Children: 0,
      ChildAges: [],
    }],
    calendarSuggestions: {
      forced: false,
      homebound: {},
      outbound: {},
    },
    selectedStartDate: {
      'currentDate': null,
      'selectedDate': false,
      'selectedDateTime': false,
      'selectedHour': '00',
      'selectedMinute': '00',
      'selectedDatesItem': '',
      'selectedDates': [],
      'dateRange': {
        'start': '',
        'end': '',
      },
      'multipleDateRange': [],
    },
    mpis: null,

    currentSliderDate: 3,

    loading: false,
    loadingFlights: false,
    dateSuggestions: [
      { loading: true }, { loading: true }, { loading: true },
      { date: null, price: null },
      { loading: true }, { loading: true }, { loading: true },
    ],
    recommendedFlights: { quickest: null, cheapest: null, recommended: null },

    flightOffers: [],
    activeFlightOffer: null,
    selectedFlight: null,
    selectedFlightGroup: null,

    selectedRooms: [],
    hotelOptions: {},

    error: false,
    nothingFound: false,
    hotelError: false,
    noHotelsFound: false,
    searchingAlternatives: false,

    modalRoomTypes: [],
    modalHotelId: null,

    timedout: false,

    stateObjects: [],
  }),
  getters: {},
  actions: {
    //old mutations
    SET_STARTPAGE (data) {
      this.startpage = data
    },

    SET_TAGS_FEATURED (data) {
      this.tagsFeatured = data
    },

    SET_TIMEDOUT (data) {
      this.timedout = data
    },
    RESET_STATEOBJECT () {
      this.stateObjects = []
    },

    SET_STATEOBJECT ({ stateObject, step }) {
      this.stateObjects[step] = stateObject
    },

    SET_SEARCHOPTIONS (options) {
      this.selectedAirport = options.selectedAirport || this.selectedAirport
      this.selectedDestination = options.selectedDestination || this.selectedDestination
      this.selectedDestinationL1 = options.selectedDestinationL1 || this.selectedDestinationL1
      this.rooms = options.rooms || this.rooms
      this.selectedStartDate = options.selectedStartDate || this.selectedStartDate
    },

    SET_SELECTED_STARTDATE (data) {
      this.selectedStartDate = data
    },

    SET_SELECTED_AIRPORT (data) {
      this.selectedAirport = data
    },

    SET_SELECTED_DESTINATION (data) {
      this.selectedDestination = data
    },

    SET_RECOMMENDED (flights) {
      this.recommendedFlights = flights
    },

    SET_LOADING (loading) {
      this.loading = loading
    },

    SET_MPIS (data) {
      this.mpis = data
    },

    SET_SEARCHING_ALTERNATIVES (data) {
      this.searchingAlternatives = data
    },

    SET_LOADINGFLIGHTS (loading) {
      this.loadingFlights = loading
    },

    SET_FLIGHTOFFERS (flightOffers) {
      this.flightOffers = flightOffers
    },

    SET_ACTIVE_FLIGHTOFFER (data) {
      this.activeFlightOffer = data
    },

    SET_CURRENT_SLIDERDATE (data) {
      this.currentSliderDate = data
    },

    SET_SELECTED_FLIGHT (data) {
      this.selectedFlight = data
    },

    SET_SELECTED_FLIGHTGROUP (data) {
      this.selectedFlightGroup = data
    },

    SET_SELECTED_ROOMS (data) {
      this.selectedRooms = data
    },

    SET_CALENDAR_SUGGESTIONS (data) {
      this.calendarSuggestions = data
    },

    SET_SUGGESTIONS (data) {
      this.dateSuggestions = data
    },

    SET_SUGGESTION ({ index, info }) {
      const dateSuggestions = [...this.dateSuggestions]
      dateSuggestions[index] = info
      this.dateSuggestions = dateSuggestions
    },

    // eslint-disable-next-line
    SET_SUGGESTION_BY_DATE ({ result, date_end }) {
      const dateSuggestions = [...this.dateSuggestions]

      const index = dateSuggestions.findIndex(d => d.date === result.date)

      dateSuggestions[index] = {
        ...result,
        date_end,
      }

      this.dateSuggestions = dateSuggestions
    },

    SET_RECOMMENDED_SUGGESTION_SEGMENTS (data) {
      this.dateSuggestions[3].segments = data
    },

    SET_NOTHINGFOUND (nothingFound) {
      this.nothingFound = nothingFound
    },

    SET_NOHOTELSFOUND (noHotelsFound) {
      this.noHotelsFound = noHotelsFound
    },

    SET_HOTELOPTIONS ({ id, hotels, minimumPrice, recommended }) {
      const hotelOptions = {
        ...this.hotelOptions,
      }
      hotelOptions.recommended = hotelOptions.recommended || {}
      hotelOptions.recommended[id] = recommended
      hotelOptions.minimumPrice = minimumPrice
      hotelOptions[id] = hotels
      this.hotelOptions = hotelOptions
    },

    RESET_HOTELOPTIONS () {
      this.hotelOptions = {}
    },

    SET_HOTELERROR (error) {
      this.hotelError = error
    },

    SET_ERROR (error) {
      this.error = error
    },

    SET_MODALROOMTYPES (data) {
      this.modalRoomTypes = data
    },

    SET_MODALHOTELID (data) {
      this.modalHotelId = data
    },

    SET_ROOMS (data) {
      this.rooms = data
    },

    SET_DESTINATION (data) {
      this.destination = data
    },
    //old actions
    async fetchStart () {
      //TODO: removed { airport } from fetchStart
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      const airportString = ''
      // if (airport) {
      //   const airportCode = (airport?.airport || airport)
      //   airportString = airportCode ? `?airport=${airportCode}` : ''
      // }
      const { data: res } = await apiFetch(`/${locale}/charter/startpage${airportString}`)
      this.SET_STARTPAGE(res)
    },

    async fetchTagsFeatured () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      const { data: res } = await apiFetch(`/${locale}/charter/tags/featured`)

      this.SET_TAGS_FEATURED(res)
      return res
    },

    async fetchDestination ({ destination }) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale
      const charterDestinationStore = useCharterDestinationStore()
      const charterDestination = charterDestinationStore.GET_CHARTER_DESTINATION

      if (charterDestination.id === destination){
        this.SET_DESTINATION(charterDestination)
        return charterDestination
      }

      const { data: result } = await apiFetch(`/${locale}/destination_page/${destination}`)
      if (result.sections) {
        const sectionDestinationSummaryIndex = result.sections.findIndex(({ type }) => type === 'destination_summary')

        if (sectionDestinationSummaryIndex !== -1 && sectionDestinationSummaryIndex) {
          result.sections[sectionDestinationSummaryIndex] = {
            ...result.sections[sectionDestinationSummaryIndex],
            data: toUnixNewlines(result.sections[sectionDestinationSummaryIndex].data, ['text', 'preamble']),
          }
        }
      }

      this.SET_DESTINATION(result)

      return result
    },

    getURLParameters (params) {
      let options = []

      if (params.selectedAirport?.airport) {
        options.push(`airport=${params.selectedAirport.airport}`)
      }

      if (params.selectedStartDate?.dateRange?.start && params.selectedStartDate?.dateRange?.end) {
        options.push(
          `date=${dayjs(params.selectedStartDate.dateRange.start).format('YYYY-MM-DD')}`,
          `date_end=${dayjs(params.selectedStartDate.dateRange.end).format('YYYY-MM-DD')}`)
      }

      if (params.selectedDestination?.destination_page_id) {
        options.push(`destination_page=${params.selectedDestination.destination_page_id}`)
      } else if (params.selectedDestination?.destination_id) {
        options.push(`destination_id=${params.selectedDestination?.destination_id}`)
      } else if (params.selectedDestination?.longitude) {
        options.push(`destination_lng=${params.selectedDestination.longitude}`)
        options.push(`destination_lat=${params.selectedDestination.latitude}`)
        options.push(`destination_addr=${params.selectedDestination.formatted_address}`)
      }

      if (params.hotel) {
        options.push(`hotel=${params.hotel}`)
      }

      if (params.rooms) {
        options = [...options, ...parseRoomsToURL(params)]
      }

      return options.join('&')
    },

    async search (options) {
      const localeStore = useLocaleStore()
      const rootStore = useRootStore()
      const bookStore = useBookStore()
      const locale = localeStore.locale

      this.SET_SEARCHOPTIONS(deepClone(options))
      this.SET_FLIGHTOFFERS([])
      this.RESET_HOTELOPTIONS()
      this.SET_TIMEDOUT(false)
      this.SET_NOTHINGFOUND(false)
      this.SET_HOTELERROR(false)
      this.SET_NOHOTELSFOUND(false)
      this.SET_ACTIVE_FLIGHTOFFER(null)
      this.SET_SELECTED_FLIGHTGROUP(null)
      this.SET_SELECTED_FLIGHT(null)
      this.SET_RECOMMENDED({ quickest: null, cheapest: null, recommended: null })
      this.SET_MODALHOTELID(null)
      this.SET_MODALROOMTYPES([])

      this.SET_ERROR(false)

      if (!dayjs(this.selectedStartDate?.dateRange?.start).isValid() || !dayjs(this.selectedStartDate?.dateRange?.end).isValid()) {
        this.SET_ERROR(true)
        return false
      }

      const searchOptions = {
        rooms: this.rooms,
      }

      let fail = false

      if (this.price) {
        searchOptions.price_suggested = this.price
      }

      if (this.selectedAirport) {
        searchOptions.airport = this.selectedAirport.airport
      } else {
        fail = true
      }

      if (this.selectedDestination && this.selectedDestination.custom) {
        searchOptions.latitude = this.selectedDestination.latitude
        searchOptions.longitude = this.selectedDestination.longitude
      } else if (this.selectedDestination) {
        searchOptions.destination_id = this.selectedDestination.destination_id
      } else {
        fail = true
      }

      if (this.selectedStartDate.dateRange) {
        searchOptions.date = dayjs(this.selectedStartDate.dateRange.start).format('YYYY-MM-DD')
        searchOptions.date_end = dayjs(this.selectedStartDate.dateRange.end).format('YYYY-MM-DD')
      } else {
        fail = true
      }

      if (this.mpis !== null) {
        searchOptions.mpis = this.mpis
      }

      if (fail) {
        this.SET_LOADINGFLIGHTS(false)
        this.SET_LOADING(false)
        this.SET_SEARCHING_ALTERNATIVES(false)
        return false
      }

      if (!options.keepSuggestions) {
        this.SET_LOADING(true)
        this.SET_SUGGESTIONS([
          {
            loading: true,
            date: dayjs(this.selectedStartDate.dateRange.start).subtract(3, 'day').format('YYYY-MM-DD'),
            date_end: dayjs(this.selectedStartDate.dateRange.end).subtract(3, 'day').format('YYYY-MM-DD'),
          }, { loading: true }, { loading: true },
          {
            date: this.selectedStartDate.dateRange.start,
            price: null,
            original: true,
            segments: 0,
          },
          { loading: true }, { loading: true }, {
            loading: true,
            date: dayjs(this.selectedStartDate.dateRange.start).add(3, 'day').format('YYYY-MM-DD'),
            date_end: dayjs(this.selectedStartDate.dateRange.end).add(3, 'day').format('YYYY-MM-DD'),
          },
        ])

        this.SET_SEARCHING_ALTERNATIVES(false)
      } else {
        this.SET_LOADINGFLIGHTS(true)
      }

      if (!options.keepSuggestions) {
        // Small delay so offers gets fired off first
        window.setTimeout(() => this.getDateSuggestions(searchOptions), 50)
      }

      try {
        window.localStorage.setItem('sol_charter_search', JSON.stringify({
          selectedAirport: this.selectedAirport,
          rooms: this.rooms,
          selectedStartDate: this.selectedStartDate,
          selectedDestination: this.selectedDestination,
          selectedDestinationL1: this.selectedDestinationL1,
        }))
      } catch {
        // do nothing
      }

      try {
        if (process.browser) {
          if (window.cab) {
            window.cab.abort()
          }

          window.cab = new AbortController()
          abortTimeout(window.cab, 60 * 1000)
        }

        const { data: result } = await apiFetch(`/${locale}/charter/offers`, {
          method: 'POST',
          body: searchOptions,
          ...(process.browser ? { signal: window.cab.signal } : {}),
        })

        this.SET_LOADING(false)
        this.SET_LOADINGFLIGHTS(false)

        if (result.flight_offers?.length) {
          const airportNames = {}

          result.airport_dictionary.forEach((a) => {
            airportNames[a.iata] = a
          })

          rootStore.SET_CHARTER_AIRPORT_NAMES(airportNames)

          this.SET_STATEOBJECT({ stateObject: result.state, step: 0 })
          bookStore.SET_TIMEOUT(result.bprocess_timeout)
          this.SET_FLIGHTOFFERS(result.flight_offers)
          this.SET_RECOMMENDED({
            quickest: result.quickest_flightid,
            cheapest: result.cheapest_flightid,
            recommended: result.recommended_flightid,
          })

          const recommended = result.flight_offers.find(f => f.id === result.recommended_flightid)

          if (!options.keepSuggestions) {
            this.SET_RECOMMENDED_SUGGESTION_SEGMENTS(recommended.itinieraries[0].segments.length + recommended.itinieraries[1].segments.length)
          }

          this.SET_SEARCHING_ALTERNATIVES(false)
        } else {
          this.SET_LOADINGFLIGHTS(true)
          this.SET_SEARCHING_ALTERNATIVES(true)
          this.getAlternativeStartDate(options)
        }

        return result
      } catch (e) {
        if (e.name === 'AbortError') {
          return
        }
        const { $sentryCaptureMessage } = useNuxtApp()

        $sentryCaptureMessage('charter/search error', {
          level: 'warning',
          contexts: {
            details: e.data || e,
          },
          tags: {
            type: 'UX',
          },
        })

        this.SET_LOADING(false)
        this.SET_LOADINGFLIGHTS(false)
        this.SET_ERROR(true)
      }
    },

    getAlternativeStartDate (options) {
      if (this.dateSuggestions.reduce((acc, curr) => { return acc || curr.loading }, false)) {
        window.setTimeout(() => this.getAlternativeStartDate(options), 50)
        return
      }

      const searchOptions = deepClone(options)

      searchOptions.triedIndexes = searchOptions.triedIndexes || []

      let index = ([[2, 4], [1, 5], [0, 6]])
        .find((i) => {
          const day = this.dateSuggestions[i[0]]
          const day2 = this.dateSuggestions[i[1]]

          return ((!day.loading && day.price) || (!day2.loading && day2.price))
        })

      if (index !== undefined) {
        const day = this.dateSuggestions[index[0]]
        const day2 = this.dateSuggestions[index[1]]

        if (day.price && day2.price) {
          index = day.price <= day2.price ? index[0] : index[1]
        } else {
          index = day.price ? index[0] : index[1]
        }
      }

      if (index === undefined) {
        this.SET_LOADING(false)
        this.SET_LOADINGFLIGHTS(false)
        this.SET_NOTHINGFOUND(true)
        return
      }
      this.SET_CURRENT_SLIDERDATE(index)

      const suggestion = this.dateSuggestions[index]

      searchOptions.selectedStartDate.dateRange.start = dayjs(suggestion.date).format(DATEPICKER_DATE_FORMAT)
      searchOptions.selectedStartDate.dateRange.end = dayjs(suggestion.date_end).format(DATEPICKER_DATE_FORMAT)
      searchOptions.price = suggestion.price
      searchOptions.keepSuggestions = true
      searchOptions.triedIndexes.push(index)

      this.search(searchOptions)
    },

    async getCalendarSuggestions (info) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      const { data: result } = await apiFetch(`/${locale}/charter/calendar_suggestions`, {
        method: 'POST',
        body: info,
      })

      const calendarSuggestions = {}

      calendarSuggestions.forced = result.forced
      calendarSuggestions.homebound = result.homebound
      calendarSuggestions.outbound = result.outbound

      this.SET_CALENDAR_SUGGESTIONS(calendarSuggestions)
    },

    getDateSuggestions (info) {
      ([1, 2, 3]).forEach(async (i) => {
        const localeStore = useLocaleStore()
        const locale = localeStore.locale

        const a = {
          ...info,
          date: dayjs(info.date).add(i, 'day').format('YYYY-MM-DD'),
          date_end: dayjs(info.date_end).add(i, 'day').format('YYYY-MM-DD'),
        }
        const b = {
          ...info,
          date: dayjs(info.date).subtract(i, 'day').format('YYYY-MM-DD'),
          date_end: dayjs(info.date_end).subtract(i, 'day').format('YYYY-MM-DD'),
        }
        try {
          let skipBefore = false

          if (dayjs(b.date, 'YYYY-MM-DD').isBefore(dayjs().subtract(1, 'day'))) {
            skipBefore = true
          }
          //TODO: needs to be tested
          const [{ data: after },{ data: before }] = await Promise.all([
            apiFetch(`/${locale}/charter/day_suggestion`, {
              method: 'POST',
              body: a,
              headers: {
                'Content-Type': 'application/json',
              },
              timeout: 60000,
            }),
            ...(skipBefore ? [{ error: true }] : [apiFetch(`/${locale}/charter/day_suggestion`, {
              method: 'POST',
              body: b,
              headers: {
                'Content-Type': 'application/json',
              },
              timeout: 60000,
            })]),
          ])
          if (after.error) {
            this.SET_SUGGESTION({
              index: i + 3,
              info: { loading: false, date: a.date, date_end: a.date_end },
            })
          } else {
            this.SET_SUGGESTION({
              index: i + 3,
              info: {
                ...after,
                date_end: a.date_end,
              },
            })
          }
          if (before.error) {
            this.SET_SUGGESTION({
              index: 3 - i,
              info: { loading: false, date: b.date, date_end: b.date_end },
            })
          } else {
            this.SET_SUGGESTION({
              index: 3 - i,
              info: {
                ...before,
                date_end: b.date_end,
              },
            })
          }
        } catch (e) {
          if (e.name === 'AbortError') {
            return
          }

          this.SET_SUGGESTION({
            index: i + 3,
            info: { loading: false, date: a.date, date_end: a.date_end },
          })
          this.SET_SUGGESTION({
            index: 3 - i,
            info: { loading: false, date: b.date, date_end: b.date_end },
          })
        }
      })
    },

    async getDateSuggestion (index) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      const searchOptions = {
        rooms: this.rooms,
        airport: this.selectedAirport.airport,
      }

      if (this.selectedDestination && this.selectedDestination.custom) {
        searchOptions.latitude = this.selectedDestination.latitude
        searchOptions.longitude = this.selectedDestination.longitude
      } else if (this.selectedDestination) {
        searchOptions.destination_id = this.selectedDestination.destination_id
      }
      if (index === -1) {
        searchOptions.date = dayjs(this.dateSuggestions[0].date).subtract(1, 'day').format('YYYY-MM-DD')
        searchOptions.date_end = dayjs(this.dateSuggestions[0].date_end).subtract(1, 'day').format('YYYY-MM-DD')

        const suggestions = deepClone(this.dateSuggestions)
        suggestions.unshift({
          loading: true,
          date: searchOptions.date,
          date_end: searchOptions.date_end,
        })
        this.SET_SUGGESTIONS(suggestions)
      } else {
        searchOptions.date = dayjs(this.dateSuggestions[this.dateSuggestions.length - 1].date).add(1, 'day').format('YYYY-MM-DD')
        searchOptions.date_end = dayjs(this.dateSuggestions[this.dateSuggestions.length - 1].date_end).add(1, 'day').format('YYYY-MM-DD')

        const suggestions = deepClone(this.dateSuggestions)
        suggestions.push({
          loading: true,
          date: searchOptions.date,
          date_end: searchOptions.date_end,
        })
        this.SET_SUGGESTIONS(suggestions)
      }

      try {
        const { data: result } = await apiFetch(`/${locale}/charter/day_suggestion`, {
          method: 'POST',
          body: searchOptions,
        })

        this.SET_SUGGESTION_BY_DATE({ result, date_end: searchOptions.date_end })
      } catch {
        this.SET_SUGGESTION_BY_DATE({ result: { loading: false, date: searchOptions.date }, date_end: searchOptions.date_end })
      }
    },

    async getHotels ({ id, storeSuggestion, setPrice }) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      this.SET_HOTELERROR(false)
      this.SET_NOHOTELSFOUND(false)

      try {
        const { data: result } = await apiFetch(`/${locale}/charter/property_list`, {
          method: 'POST',
          body: {
            state: this.stateObjects[0],
            flight_offer_id: id,
          },
        })

        if (result.property_list.length) {
          this.SET_HOTELOPTIONS({
            id,
            recommended: result.recommended_properties,
            minimumPrice: result.minimum_package_price,
            hotels: result.property_list,
          })

          if (storeSuggestion) {
            const suggestions = deepClone(this.dateSuggestions)
            if (setPrice) {
              let minPrice = null
              if (result.recommended_properties?.length) {
                const hotels = {}
                result.property_list.forEach((h) => { hotels[h.id] = h })

                // const max = 9999999999999999
                const max = Number.MAX_SAFE_INTEGER

                minPrice = result.recommended_properties
                  .filter(h => !!hotels[h.hotel_id])
                  .reduce((acc, curr) => { return hotels[curr.hotel_id].min_additional_cost < acc ? hotels[curr.hotel_id].min_additional_cost : acc }, max)

                if (minPrice === max) {
                  minPrice = null
                }
              }

              const activeDateSlide = suggestions.find(({ date }) => date === storeSuggestion)
              if (activeDateSlide) {
                activeDateSlide.price = minPrice || result.minimum_package_price
              }
            }
            this.SET_SUGGESTION({ index: storeSuggestion, data: suggestions[storeSuggestion] })
          }
        } else {
          this.SET_NOHOTELSFOUND(true)
        }

        this.SET_STATEOBJECT({ stateObject: result.state, step: 1 })

        return result
      } catch (e) {
        if (e.data?.message === 'state_expired') {
          this.SET_TIMEDOUT(true)

          throw e
        }

        this.SET_HOTELERROR(true)
      }
    },

    async hotelInfo (giataId) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      try {
        if (process.browser) {
          if (window.cab) {
            window.cab.abort()
          }

          window.cab = new AbortController()
          abortTimeout(window.cab, this.timeout)
        }

        const { data: result } = await apiFetch(
          `/${locale}/booking/property-info/${giataId}`,
          process.browser ? { signal: window.cab.signal } : {}
        )
        return result
      } catch (e) {
        if (e.data?.message === 'state_expired') {
          this.SET_TIMEDOUT(true)
        }

        throw e
      }
    },

    async roomData (info) {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      info.state = this.stateObjects[1]

      try {
        if (process.browser) {
          if (window.cab) {
            window.cab.abort()
          }

          window.cab = new AbortController()
          abortTimeout(window.cab, this.timeout)
        }

        const { data: result } = await apiFetch(`/${locale}/booking/roomtypes-for-hotel`, {
          method: 'POST',
          body: info,
          ...(process.browser ? { signal: window.cab.signal } : {}),
        })

        this.SET_STATEOBJECT({ stateObject: result.state, step: 2 })
        this.SET_MODALROOMTYPES(result.roomtypes)
        this.SET_MODALHOTELID(info.hotelid)

        return result
      } catch (e) {
        if (e.data?.message === 'state_expired') {
          this.SET_TIMEDOUT(true)
        }

        throw e
      }
    },

    async customerData (data) {
      const bookStore = useBookStore()
      data.state = this.stateObjects[2]

      bookStore.SET_TRAVELERS([])
      bookStore.SET_MANAGER(null)
      bookStore.SET_TRIPINFO({})
      bookStore.SET_ISROUNDTRIP(false)

      try {
        const result = await bookStore.customerData(data)

        return result
      } catch (e) {
        if (e.data?.message === 'state_expired') {
          this.SET_TIMEDOUT(true)
        }

        throw e
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCharterStore, import.meta.hot))
}