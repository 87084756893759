// Migrated
<template lang="pug">
.container-fluid.container-content
  .row
    .col-xl-10.col-12.mx-auto.px-0.px-md-3.px-xl-0.mb-5
      .w-100
        .mt-n4
          .mt-n4.px-3.px-md-0
            HydrateWhenVisible.min-h-px-900.min-h-px-md-200
              LazyDeparturesTab(
                v-if="tripTab == 'details' || tripTab == 'info'",
                :key="`tab-${trip?.id}`"
                :calendar="calendar",
                :flight-trip="!!trip.flight_trip",
                :main-tab="true",
                :loading="loading"
                :show-seats-left="!destinationPage"
              )

        HydrateWhenVisible.min-h-px-2600.min-h-px-md-1300
          LazySuggested(
            v-if="suggested && suggested.length",
            :key="`suggested-${trip?.id}`"
            :suggested="suggested"
          )
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  data () {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapState(useTripStore, {
      suggested: 'suggestedTriptypes',
      trip: 'trip',
      tripTab: 'tripTab',
      calendar: 'calendar',
    }),

    destinationPage () {
      return /^\/destination/.test(this.$route.path)
    },
  },
})
</script>
