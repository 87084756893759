// Translated
// Migrated
<template lang="pug">
.container-fluid.sticky-top.header-nav__bg.px-0
  .row.global-alert(
    v-if="alert.show && !sideMenuActive && !alertHidden",
    :class="{ 'scrolling-header': scrolling }"
  )
    .col-xl-10.mx-auto
      .row
        .col-12.text-align-center(v-html="alert.text")
  .row.container-content.mx-auto.position-relative
    .col-xxl-10.mx-auto.position-static
      ScreenWidthProvider(v-slot="{ isWider, isNarrower }")
        .row.mx-0
          nav.navbar.navbar-expand-lg.navbar-light.header-nav.flex-nowrap(
            style="z-index: 10"
            :class="`header--${$isSol ? 'solresor' : 'rolfs'}`"
          )
            .d-flex.align-items-center(@click="closeSideMenuActive")
              nuxt-link.navbar-brand.pl-0(to="/")
                img#sitelogo(
                  :src="logoImg"
                  alt="logo"
                  :height="$isSol ? 80 : 42"
                  :width="$isSol ? 192 : 340"
                )

            #navbarSupportedContent.collapse.navbar-collapse
              ul.navbar-nav.ml-auto
                li.nav-item
                  LazyLoginHeaderContainer(
                    v-if="!$isSol && isWider('lg')"
                    :drop="isWider(1400) ? 'right' : 'left'"
                    :show-searchform="showSearchform"
                  )

                li.nav-item(
                  v-for="(navItem, index) in topNavbar",
                  :key="index",
                  :class="{ dropdown: navItem.submenu, searchActive: showSearchform, highlight: navItem.highlight, 'px-md-1 px-lg-2 px-mxl-4': !$isSol }",
                  :data-test-id="navItem.id"
                  @click="clickNavItemHandle(`nav-${index}`, navItem)",
                  @mouseover="activeNav = `nav-${index}`",
                  @mouseleave="activeNav = ''"
                )
                  nuxt-link.nav-link.d-flex.align-items-center.h-100(
                    v-if="navItem.link && !navItem.submenu",
                    :class="navItem.submenu ? 'dropdown-toggle' : ''",
                    :to="navItem.link"
                  ) {{ navItem.label }}
                  button.nav-link.dropdown-toggle(
                    v-if="navItem.submenu && navItem.submenu.length"
                    type="button",
                  ) {{ navItem.label }}
                    fa.ml-1.ml-xl-2(
                      v-if="navItem.submenu"
                      icon="chevron-down"
                      width="11"
                    )
                  .dropdown-menu(
                    v-if="navItem.submenu",
                    :class="{ 'd-block': activeNav === `nav-${index}`, 'dropdown-menu-left': isWider('mxl'), 'dropdown-menu-right': isNarrower('mxl') }"
                  )
                    div(
                      v-for="(submenuItem, idx) in navItem.submenu",
                      :key="'submenu' + idx"
                    )
                      .dropdown-divider(v-if="submenuItem.label === 'linebreak'")
                      a.dropdown-item(
                        v-else-if="submenuItem.absoluteLink",
                        :href="submenuItem.link",
                        target="_blank"
                      ) {{ submenuItem.label }}
                      nuxt-link.dropdown-item(
                        v-else
                        :to="submenuItem.link"
                      ) {{ submenuItem.label }}

                LazyFavoriteHeaderLink(v-if="$isSol")

                li.nav-item.nav-item-search.position-relative(
                  :class="{ searchActive: showSearchform }"
                )
                  button.nav-link.header-nav-search-btn.h-100(
                    type="button"
                    :aria-label="$t('searchButtonText')",
                    @click="searchBtnHandler"
                  )
                    fa(
                      icon="search"
                      width="11"
                    )

                  transition(name="slide-fade")
                    .header-nav-search-form(v-if="showSearchform")
                      LazyAlgoliaSearchBar(
                        v-if="showSearchform"
                        hide-on-click-outside
                        :header="true"
                        autofocus
                        @hide-search-bar="showSearchform = false"
                      )

                li.header-phone(v-if="!$isSol")
                  a.text-black.d-flex.flex-column.justify-content-center.align-items-start(
                    :class="{ 'contact-small': showAgentCalls }"
                    :href="`tel:${$t('companyPhone')}`"
                  )
                    span.pl-4(data-i18n="header.phoneInfo1") {{ $t('header.phoneInfo1') }}
                    span.pl-4(data-i18n="header.phoneInfo2") {{ $t('header.phoneInfo2') }}

            .mobile-menu.d-lg-none.d-flex.align-items-center
              HydrateWhenVisible.favourites-item.w-auto.min-h-px-30(
                v-if="$isSol"
              )
                LazyFavoriteHeaderLink(:is-mobile="true")
              template(v-else-if="isNarrower('lg')")
                LazyLoginHeaderContainer(
                  :drop="isNarrower('sm') ? 'center' : 'right'"
                  :show-searchform="showSearchform"
                  :is-mobile="true"
                )
              button.mobile-btn.mobile-menu-all-trips(
                type="button"
                title="menu"
                :class="{ _active: sideMenuActive }"
                @click="toggleSideMenuActive"
              )
                span.mr-2.text-nowrap(
                  v-if="!$isSol"
                  data-i18n="allTrips"
                ) {{ $t('allTrips') }}
                fa(
                  :icon="sideMenuActive ? 'times' : 'bars'"
                  width="13"
                )
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { faUser } from '@fortawesome/pro-duotone-svg-icons'

import scrollingMixin from '@layers/web/mixins/scrolling.mixin.js'
import events from '@layers/web/constants/events.js'

const readyStateComplete = 'complete'

export default defineNuxtComponent({
  mixins: [scrollingMixin],

  emits: [events.pageIsReady],

  data () {
    return {
      icons: {
        faUser,
      },

      showSearchform: false,
      searchPhrase: '',
      activeNav: '',
      previousScrollY: 0,
      isAppleSafari: false,
      timer: null,
    }
  },

  computed: {
    ...mapState(useLocaleStore, [
      'canLocaleLogin',
    ]),

    ...mapState(useRootStore, {
      showAgentCalls: 'shouldShowAgentCalls',
      topNavbar: 'topNavbar',
      alert: 'alert',
    }),

    ...mapState(useMenuStore, {
      sideMenuActive: 'sideMenuActive',
      alertHidden: 'alertHidden',
    }),

    logoImg () {
      return formatCloudinaryImagePath(this.$t('logo2'), {
        transformation: { custom: `w_${this.logoSize.width * 2}` },
        isSol: this.$isSol,
      }
      )
    },

    logoSize () {
      return this.$isSol ? { width: 230, height: 96 } : { width: 340, height: 42 }
    },

    logoSizeMobile () {
      return this.$isSol ? { width: 138, height: 40 } : { width: 196, height: 24 }
    },
  },

  watch: {
    sideMenuActive (v) {
      if (!this.isAppleSafari) {
        return
      }
      if (v) {
        this.previousScrollY = window.scrollY
        window.document.body.classList.add('sideMenuActive')
        window.document.documentElement.classList.add('sideMenuActive')
      } else {
        window.document.body.classList.remove('sideMenuActive')
        window.document.documentElement.classList.remove('sideMenuActive')
        window.scrollTo(0, this.previousScrollY)
      }
    },
  },

  created () {
    if (process.browser) {
      // eslint-disable-next-line
      window.addEventListener('click', this.clickHandler)
      this.initCookies()
    }
  },

  beforeUnmount () {
    window.removeEventListener('click', this.clickHandler)
    clearTimeout(this.timer)
  },

  mounted () {
    this.isAppleSafari = isIOSSafari

    this.handlePageInit()

    /*
    ** Prevent iOS Safari modal scrolling bug
    ** https://stackoverflow.com/questions/43563795/bootstrap-modal-background-scroll-on-ios/52591624
    */
    if (!this.isAppleSafari) {
      return
    }
    this.$event.$on('bv::modal::show', () => {
      this.previousScrollY = window.scrollY
    })
    this.$event.$on('bv::modal::shown', () => {
      window.document.body.classList.add('sideMenuActive')
      window.document.documentElement.classList.add('sideMenuActive')
    })
    this.$event.$on('bv::modal::hide', () => {
      window.document.body.classList.remove('sideMenuActive')
      window.document.documentElement.classList.remove('sideMenuActive')
    })
    this.$event.$on('bv::modal::hidden', () => {
      window.scrollTo(0, this.previousScrollY)
    })
  },

  methods: {
    ...mapActions(useMenuStore, ['toggleSideMenuActive', 'closeSideMenuActive']),
    ...mapActions(useRootStore, ['handleAgentCookie']),

    clickHandler (e) {
      // wasn't removing listener
      if (!this.$el.contains(e.target)) {
        this.activeNav = ''
      }
    },

    searchBtnHandler () {
      this.showSearchform = !this.showSearchform
    },

    clickNavItemHandle (curNav, navItem) {
      if (navItem.link && !navItem.submenu) {
        return
      }

      if (this.activeNav === curNav) {
        this.activeNav = ''
      } else if (this.activeNav === '') {
        this.activeNav = curNav
      }
    },

    initCookies () {
      if (!this.$isSol) {
        if (this.$router.currentRoute.value?.query?.a) {
          // Seems the SSR setting (nuxtServerInit) isn't entirely reliable.
          // So to ensure no cookie sets faulty, we double check.
          this.handleAgentCookie(this.$router.currentRoute.value?.query?.a)
        }
      }
    },

    handlePageInit () {
      const onReadyState = this.handleReadyState
      document.onreadystatechange = function () {
        onReadyState(document.readyState)
      }

      this.timer = window.setTimeout(() => {
        this.handleReadyState(readyStateComplete)
      }, 10000)
    },

    handleReadyState (state) {
      this.$event.$emit(events.pageIsReady, state)

      if (state === readyStateComplete) {
        window.document.body.classList.add('--loaded')
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.slide-fade {
  &-enter-from,
  &-leave-to {
    transform: translateX(50px);
    opacity: 0;
  }

  &-enter-to,
  &-leave-from {
    transform: translateX(0);
    opacity: 1;
  }

  &-enter-active {
    transition: all 0.3s ease;
  }

  &-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }
}
.header-nav #navbarSupportedContent ul li.header-phone.contact-small span {
  font-size: 8px;
}
.header--rolfs {
  .nav-item-search,
  .login-header {
    padding: 0 15px;
  }

  #sitelogo {
    max-width: 340px;

    @media (max-width: 1280px) {
      max-width: 260px;
    }
    @media (max-width: 1080px) {
      max-width: 200px;
      height: auto;
    }
  }
}
.header--solresor {
  &.header-nav {
    position: static;

    @media (max-width: $xxl) {
      padding: 0;
    }

    .header-nav-search-btn {
      svg {
        height: 16px;
        width: 16px;
      }
    }

    #navbarSupportedContent ul li {
      .nav-link {
        text-transform: none;
        font-size: .95rem !important;
        padding: 0 17px;
        display: flex;
        align-items: center;

        svg {
          margin-left: 2px;
        }
      }

      @media (max-width: $mxl) {
        .nav-link {
          padding: 0 17px;
        }
      }

      @media (max-width: $sxl) {
        .nav-link {
          padding: 0 21px;
          width: min-content;
          white-space: break-spaces;
          text-align: center;
        }
      }

      .identity--solresor & {
        .nav-link {
          // Fix "Utflykter" tab overlapping logo
          @media (max-width: 1464px) {
            padding: 0 12px;
            font-size: 0.85rem !important;
          }
        }
      }
    }
  }
  .navbar {
    &-brand {
      width: 270px;
      border-top-right-radius: 96px;
      position: absolute;
      left: 0;
      background: $orange;
      padding: 12px 34px !important;
      height: 100%;
      align-items: center;

      img {
        height: 100%;
      }

      @media (max-width: $xl) {
        width: 250px;
        padding: 12px 25px !important;
      }

      @media (max-width: ($lg - 1px)) {
        padding: 6px 20px !important;
      }
    }
    &-collapse {
      position: absolute;
      right: 35px;

      @media (max-width: $mxl) {
        right: 0;
      }
    }
  }
}
.favourites-item {
  border: none !important;

  .hide {
    display: none !important;
  }
}
</style>
