<template lang="pug">
component(
  :is="as"
  :id="id"
  ref="containerRef"
)
  slot
</template>

<script setup>
/**
 * Containing text gets smaller if overlapping container
 * Children should only contain text
 */
const containerRef = ref(null)
const slots = useSlots()
const id = useId()

const props = defineProps({
  as: {
    type: String,
    default: 'div',
  },
  maxIterations: {
    type: Number,
    default: 150,
  },
})

let resizeObserver = null

const init = () => {
  if (typeof window === 'undefined') {
    return
  }

  updateFontSize()

  resizeObserver = new ResizeObserver(() => updateFontSize())
  resizeObserver.observe(containerRef.value)
}

const updateFontSize = () => {
  if (typeof window === 'undefined' || !containerRef.value || !slots.default?.().length) {
    return
  }

  if (containerRef.value.offsetWidth === 0) {
    return
  }

  const responsiveTexts = [...containerRef.value.children]
  responsiveTexts.forEach((child) => {
    child.style.fontSize = ''

    const computedStyle = getComputedStyle(child)
    let newFontSize = parseInt(computedStyle.fontSize)

    let iterations = 0

    while (child.getBoundingClientRect().width > containerRef.value.offsetWidth) {
      newFontSize -= 0.5
      child.style.fontSize = `${newFontSize}px`

      iterations++

      if (iterations >= props.maxIterations) {
        console.error('ResponsiveText hit max iterations', containerRef.value)
        break
      }
    }
  })
}

onMounted(() => {
  nextTick(init)
})

onBeforeUnmount(() => {
  if (resizeObserver) {
    resizeObserver.disconnect()
  }
})

onUpdated(() => {
  updateFontSize()
})
</script>