// Migrated
<template lang="pug">
a.d-flex.text-black.py-2.w-100.min-h-px-50(
  :class="{ charter: $isSol }"
)
  .col-1.m-auto.text-center
    fa(
      size="lg"
      :icon="formattedSuggestion.icon"
    )

  .col-7.col-lg-8.d-flex.justify-content-center.flex-column
    .font-weight-bold {{ formattedSuggestion.title }}
    .text-truncate.text-smaller {{ formattedSuggestion.subtitle }}
    .d-inline-flex.text-smaller.flex-wrap(
      v-if="formattedSuggestion.departures || formattedSuggestion.days"
    )
      .mr-3(v-if="formattedSuggestion.departures" data-i18n="departuresText") {{ $t('departuresText', formattedSuggestion.departures) }}
      div(v-if="formattedSuggestion.days" data-i18n="daysText") {{ $t('daysText', { n: formattedSuggestion.days } ) }}

  .col-4.col-lg-3.text-right.m-auto
    template(v-if="formattedSuggestion.price")
      small(data-i18n="shortFromPrice") {{ $t('shortFromPrice') }}
      span.text-nowrap &nbsp;{{ $n(formattedSuggestion.price) }}&nbsp;
</template>

<script setup lang="ts">
import { SEARCH_TYPES_ICONS } from '@layers/web/constants/search.ts'
import type { AlgoliaSearchResult } from '@layers/web/types/algolia.ts'

type Props = {
  suggestion: AlgoliaSearchResult
}

const props = defineProps<Props>()

const { days } = useDaysMixin()

const formattedSuggestion = computed(() => {
  switch (props.suggestion.type) {
    case 'trip':
    case 'hotel':
    case 'destination': {
      const {
        title,
        flight_trip,
        price,
        price_min,
        departures,
        charter,
        subtitle,
      } = props.suggestion

      return {
        title,
        subtitle,
        icon: flight_trip
          ? SEARCH_TYPES_ICONS.trip_flight
          : SEARCH_TYPES_ICONS.trip,
        price: price || price_min,
        departures,
        days: charter
          ? null
          : days(props.suggestion),
      }
    }

    case 'category': {
      const {
        title,
        preamble_html,
      } = props.suggestion

      return {
        title,
        subtitle: stripHTML(preamble_html),
        icon: SEARCH_TYPES_ICONS.category,
      }
    }

    case 'page': {
      const {
        topic,
        content,
      } = props.suggestion

      return {
        title: topic,
        subtitle: content,
        icon: SEARCH_TYPES_ICONS.page,
      }
    }

    case 'hardcoded': {
      const {
        title,
        description,
      } = props.suggestion

      return {
        title,
        subtitle: description,
        icon: SEARCH_TYPES_ICONS.page,
      }
    }

    case 'web_campaign': {
      const {
        topic,
        html,
      } = props.suggestion

      return {
        title: topic,
        subtitle: stripHTML(html),
        icon: SEARCH_TYPES_ICONS.web_campaign,
      }
    }

    case 'rolf-hotel': {
      const {
        name,
      } = props.suggestion

      return {
        title: name,
        icon: SEARCH_TYPES_ICONS['rolf-hotel'],
      }
    }
  }

  return {}
})
</script>

<style lang="scss">
// vue-autosuggest / algolia
.identity--rolfs {
  .autosuggest__results {
    &-container {
      position: absolute;
      top: calc(100% - .2rem);
      width: 100% !important;
    }

    .usage-info {
      font-size: 0.75rem;
      border-bottom: 1px solid $light-gray;
    }
    &-item {
      padding: 0 !important;
      border: none !important;

      &--highlighted {
        .tripsuggestion {
          background-color: $light-gray;
          color: $orange;
        }
      }
    }

    .text-smaller {
      font-size: 0.875rem;
    }

    @media (max-width: $sm) {
      font-size: 0.875rem;
      overflow-x: hidden;

      .text-smaller {
        font-size: 0.8rem;
      }
    }
  }
}

.autosuggest__results {
  &-container {
    position: absolute;
    top: calc(100% - .2rem);
    width: 100%;
  }

  .usage-info {
    font-size: 0.75rem;
    border-bottom: 1px solid $light-gray;
  }
  &-item {
    padding: 0 !important;
    border: none !important;

    &--highlighted {
      .tripsuggestion {
        background-color: $light-gray;
        color: $orange;
      }
    }
  }

  .text-smaller {
    font-size: 0.875rem;
  }

  @media (max-width: $sm) {
    font-size: 0.875rem;
    overflow-x: hidden;

    .text-smaller {
      font-size: 0.8rem;
    }
  }
}
</style>